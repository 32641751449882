import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../components/Breadcrumb";
import ProductCard from "../components/ProductCard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProductCardSkeleton from "../components/ProductCardSkeleton";
import Breadcrumbs from '../components/Breadcrumb';
import Filters2 from "../components/Filters2";
import { DataEncoder } from "../utils/common";
import { useAuth } from "../hooks/AuthProvider";
import { useFilterContext } from "../helpers/FilterContext";
import { useNavigate } from "react-router-dom";
import { Drawer, select } from "@material-tailwind/react";
import cross from "../assets/icons/close.png";
import Flatpickr from "react-flatpickr";
import { fetchAutocompleteFilters } from "../services/apiServiceAutocomplete";
import { set } from "date-fns";
import { data } from "autoprefixer";
import { ReactComponent as CartPlp } from "../assets/icons/CartPlp.svg";
import { ReactComponent as CalendarPlp } from "../assets/icons/CalendarPlp.svg";
import { ReactComponent as ZipPlp } from "../assets/icons/ZipPlp.svg";
import { ReactComponent as RemoveFilterPlp } from "../assets/icons/RemoveFilterPlp.svg";
import { ReactComponent as SearchPlp } from "../assets/icons/SearchPlp.svg";
import { ReactComponent as SearchPlp2 } from "../assets/icons/SearchPlp2.svg";
import { useError } from "../helpers/ErrorContext";
import CategorySchema from "../components/CategorySchema";

function ZipCodeInput({
  zipPostalInputValue,
  handleWhereTopBar,
  where,
  handleItemClick,
  setZipPostalInputValue,
  setWhere,
}) {
  const zipInputRef = useRef(null);
  const zipModalRef = useRef(null);

  return (
    <div className="relative flex items-center flex-1 w-full gap-3 px-4 py-4 transition-all duration-150 ease-in-out rounded-full bg-light lg:bg-white md:p-0 stroke-black hover:stroke-primary lg:w-auto">
      <ZipPlp />
      <div ref={zipInputRef} className="relative w-full">
        <input
          className="w-full font-bold text-black bg-transparent border-none appearance-none focus:outline-none placeholder-primary lg:placeholder-darkgray placeholder:font-bold focus:placeholder-gray input-postal"
          id=""
          type="text"
          placeholder="Código postal"
          value={zipPostalInputValue}
          onChange={handleWhereTopBar}
          onBlur={() => {
            if (!zipPostalInputValue) {
              setZipPostalInputValue("");
            }
          }}
        />
        {where && where.length > 0 && (
          <div
            ref={zipModalRef}
            className="absolute z-[9999999] mt-6 bg-white w-full border shadow-md min-w-max top-full rounded-xl border-zinc-100"
          >
            <div className="flex flex-col gap-4 p-4 overflow-scroll max-h-48">
              {where.map((record, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={handleItemClick(
                    record,
                    setWhere,
                    setZipPostalInputValue,
                    false
                  )}
                >
                  <span className="min-w-[15px] min-h-[15px] border border-primary inline-block rounded-full checkbox-filter"></span>
                  <input type="checkbox" className="hidden" name="" id="" />
                  <label htmlFor="" className="text-left cursor-pointer">
                    {record.text}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function Plp2() {
  const { user, isAuthenticated } = useAuth();
  const [sortByOpen, setSortByOpen] = useState(false);
  const [perPageByOpen, setPerPageByOpen] = useState(false);
  const [helenShown, setHelenShown] = useState(false);

  const toggleSortBy = () => {
    setSortByOpen(!sortByOpen);
  };
  const togglePerPage = () => {
    setPerPageByOpen(!perPageByOpen);
  };
  const getParams = window.location.search;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalProductApi, setTotalProductApi] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [where, setWhere] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const { state, dispatch } = useFilterContext();
  const productsFilter = state.urlTestParams;
  const [selectedZips, setSelectedZips] = useState(0);
  const [filtersContent, setFiltersContent] = useState({});
  const [fetchedFilters, setFetchedFilters] = useState({});
  const [searchInputValue, setSearchInputValue] = useState("");
  const [zipPostalInputValue, setZipPostalInputValue] = useState("");
  const [defaultCategory, setDefaultCategory] = useState(false);
  const keysFilters = Object.keys(state.urlTestParams.filters).filter(
    (key) => key !== "with_offers" && state.urlTestParams.filters[key]
  );
  const encoder = new DataEncoder();
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([null, null]);
  const [openWhereFilters, setOpenWhereFilters] = useState(false);
  const [perPage, setPerPage] = useState(productsFilter.per_page);
  const [sortByText, setSortByText] = useState("Por defecto");

  const productModalRef = useRef(null);
  const productInputRef = useRef(null);
  const productInputDrawerRef = useRef(null);
  const productModalDrawerRef = useRef(null);
  const [rentalOptions, setRentalOptions] = useState([]);
  const [seoText, setSeoText] = useState("");
  const [inCategory, setInCategory] = useState(false);
  const [selectedCategoriesKeywords, setSelectedCategoriesKeywords] = useState(
    []
  );
  const { setError } = useError();
  const [keywords, setKeywords] = useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;

  const handleChangePerPage = (value) => {
    setPerPage(value);
    dispatch({ type: "PER_PAGE", payload: value });
    dispatch({ type: "CHANGE_PAGE", payload: 1 });
    setPerPageByOpen(false);
  };

  useEffect(() => {
    if (
      state.urlTestParams.filters.search &&
      state.urlTestParams.filters.categories_synonyms
    ) {
      dispatch({ type: "REMOVE_FILTER", payload: "search" });
    }
  }, [state.urlTestParams.filters]);

  const fetchProducts = async (params) => {
    try {
      const url = `${API_BASE_URL}/products?${encoder.encode(params)}`;
      const response = await fetch(encodeURI(url));

      const responseData = await response.json();
      if (!response.ok) {
        const errorMessages = Object.values(responseData.errors)
          .flat()
          .join(", ");
        throw new Error(errorMessages);
      }
      const productsArray = Object.values(responseData.data);

      // this part is responsible for showing and hiding the helens menu when there are no results 
      const toggleMenuEvent = new Event("toggleAssistantMenu");
      if (productsArray.length === 0 && !helenShown) {
        window.dispatchEvent(toggleMenuEvent);
        setHelenShown(true);
      } else if (productsArray.length > 0 && helenShown) {
        window.dispatchEvent(toggleMenuEvent);
        setHelenShown(false);
      }
      
      setProducts(productsArray);
      setTotalProductApi(responseData);
      setLastPage(responseData.last_page);
      setFetchedFilters(responseData.filters);
      dispatch({ type: "SET_FILTERS", payload: responseData.filters });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const fetchFilters = async (getParams) => {
    const urlToFetch = `${API_BASE_URL}/filters?${encoder.encode(getParams)}`;
    try {
      const response = await fetch(urlToFetch);
      const responseData = await response.json();
      if (!response.ok) {
        const errorMessages = Object.values(responseData.errors)
          .flat()
          .join(", ");
        throw new Error(errorMessages);
      }
      setFiltersContent(responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const objToFilters = {
      filters: state.urlTestParams.filters,
    };
    fetchFilters(objToFilters);
  }, [state.urlTestParams.filters]);

  useEffect(() => {
    fetchProducts(productsFilter);
  }, [productsFilter]);

  const changePage = (pageNumber) => {
    dispatch({ type: "CHANGE_PAGE", payload: Number(pageNumber) });
  };

  const handleSearchInputChange = async (event) => {
    setSearchInputValue(event.target.value);
    if (event.target.value.length >= 3) {
      const results = await fetchAutocompleteFilters(
        event.target.value,
        "categories_synonyms"
      );
      setRentalOptions(results);
    } else {
      setRentalOptions([]);
      dispatch({ type: "REMOVE_FILTER", payload: "categories_synonyms" });
    }
  };

  const handleWhereTopBar = async (event) => {
    setZipPostalInputValue(event.target.value);
    if (event.target.value.length >= 3) {
      const results = await fetchAutocompleteFilters(
        event.target.value,
        "geocode_zips"
      );
      setWhere(results);
    } else {
      setWhere([]);
    }
  };

  const handleItemClick = (record, setState, setValue, isProduct) => () => {
    const value = record.data ? record.data.zip : record.value;
    if (isProduct) {
      dispatch({ type: "REMOVE_FILTER", payload: "search" });
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "categories_synonyms", values: [record.text] },
      });
      setSelectedCategoriesKeywords([
        ...selectedCategoriesKeywords,
        record.text,
      ]);
      setValue(value); // Asegúrate de que el valor se actualice
    } else {
      setSelectedZips(record.value);
      setZipPostalInputValue(value);
    }
    setState([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        productModalRef.current &&
        !productModalRef.current.contains(event.target) &&
        productInputRef.current &&
        !productInputRef.current.contains(event.target)
      ) {
        setRentalOptions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchInputValue) {
        dispatch({
          type: "UPDATE_FILTERS",
          payload: { filterId: "search", values: searchInputValue },
        });
      } else {
        dispatch({ type: "REMOVE_FILTER", payload: "search" });
      }
    }, 500); // Tiempo de retraso en milisegundos

    return () => clearTimeout(delayDebounceFn);
  }, [searchInputValue]);

  useEffect(() => {
    if (selectedZips) {
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "zip_distance", values: [selectedZips, 100] },
      });
      setOpenWhereFilters(true);
    } else {
      dispatch({ type: "REMOVE_FILTER", payload: "zip_distance" });
    }
  }, [selectedZips]);

  const handleResetFilters = (event) => {
    event.preventDefault();
    dispatch({ type: "RESET_FILTERS" });
    let currentUrl = window.location.href;
    let index = currentUrl.indexOf("alquiler");
    if (index !== -1) {
      let newUrl = currentUrl.slice(0, index + "alquiler".length);
      window.history.pushState({}, "", newUrl);
    }
    let url = new URL(window.location.href);
    url.search = "";
    window.history.pushState({}, "", url.toString());
    window.location.reload();
  };

  const [openTop, setOpenTop] = React.useState(false);
  const [openTop2, setOpenTop2] = React.useState(false);
  const openDrawerTop = () => setOpenTop(true);
  const closeDrawerTop = () => setOpenTop(false);
  const openDrawerTop2 = () => setOpenTop2(true);
  const closeDrawerTop2 = () => setOpenTop2(false);

  const removeFilter = (filterId, filter) => (event) => {
    if (filter === "search") {
      setSearchInputValue("");
      setSearchTerm("");
    }
    if (filter === "categories_ids") {
      setInCategory(false);
    }
    if (filter === "zip_distance") {
      setSelectedZips(0);
      setZipPostalInputValue("");
    }
    dispatch({ type: "REMOVE_FILTER", payload: filterId });
    let currentUrl = window.location.href;
    let index = currentUrl.indexOf("alquiler");
    if (index !== -1) {
      let newUrl = currentUrl.slice(0, index + "alquiler".length);
      window.history.pushState({}, "", newUrl);
    }
  };

  const handleDateChange = (selectedDates) => {
    const correctFormatDates = selectedDates.map(
      (date) => date.toISOString().split("T")[0]
    );
    if (correctFormatDates.length >= 2) {
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "available_dates", values: correctFormatDates },
      });
    }
    setDateRange(selectedDates);
  };

  useEffect(() => {
    const cookieWhen = getCookie("whenHome");
    const cookieSearch = getCookie("searchHome");
    const cookieWhere = getCookie("whereHome");
    const cookieWhereZip = getCookie("whereHomeZip");

    if (cookieSearch) {
      deleteCookie("searchHome");
      setSearchTerm(cookieSearch);
      setSearchInputValue(cookieSearch);
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "search", values: cookieSearch },
      });
    }

    if (cookieWhen) {
      deleteCookie("whenHome");
      const dates = cookieWhen.split(",").map((date) => new Date(date));
      handleDateChange(dates);
    }

    if (cookieWhere) {
      deleteCookie("whereHome");
      setZipPostalInputValue(cookieWhereZip);
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "zip_distance", values: [cookieWhere, 100] },
      });
    }
  }, []);

  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function deleteCookie(name) {
    document.cookie =
      name +
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure; path=/";
  }

  function handleSortPrice(order, text) {
    if (order === "") {
      dispatch({ type: "REMOVE_SORT" });
    } else {
      dispatch({ type: "SORT_BY", payload: { price: order } });
    }
    setSortByText(text);
    setSortByOpen(false);
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/");
    const alquilerIndex = pathParts.indexOf("alquiler");
    if (alquilerIndex !== -1 && alquilerIndex + 1 < pathParts.length) {
      const contentAfterAlquiler = pathParts[alquilerIndex + 1];
      if (pathParts[1] === "alquiler") {
        if (
          contentAfterAlquiler !== "maquinaria" &&
          contentAfterAlquiler !== "herramienta" &&
          contentAfterAlquiler !== "auxiliares"
        ) {
          dispatch({
            type: "UPDATE_FILTERS",
            payload: {
              filterId: "categories_ids",
              values: [contentAfterAlquiler],
            },
          });
        } else {
          let contentAfterAlquilerCapitalized =
            contentAfterAlquiler.charAt(0).toUpperCase() +
            contentAfterAlquiler.slice(1);
          dispatch({
            type: "UPDATE_FILTERS",
            payload: {
              filterId: "use_type",
              values: contentAfterAlquilerCapitalized,
            },
          });
        }
      }
    }
    const marcaIndex = pathParts.indexOf("productos-marca");
    if (marcaIndex !== -1 && marcaIndex + 1 < pathParts.length) {
      const contentAfterMarca = pathParts[marcaIndex + 1];
      dispatch({
        type: "UPDATE_FILTERS",
        payload: { filterId: "brands_ids", values: [contentAfterMarca] },
      });
    }
  }, []);

  useEffect(() => {
    if (
      totalProductApi &&
      totalProductApi.filters &&
      totalProductApi.filters.categories_ids
    ) {
      let keyValue = 0;
      let categories_ids = totalProductApi.filters.categories_ids;
      for (let key in categories_ids) {
        if (categories_ids.hasOwnProperty(key)) {
          keyValue = key;
        }
      }
      const pathSegments = window.location.pathname.split("/");
      const alquilerIndex = pathSegments.findIndex(
        (segment) => segment === "alquiler"
      );
      if (alquilerIndex !== -1 && pathSegments.length > alquilerIndex + 1) {
        if (keyValue) {
          const categorySlug = totalProductApi.filters.categories_ids[
            keyValue
          ].data.slug.replace(/-/g, " ");

          const categorieText =
            totalProductApi.filters.categories_ids[keyValue].text;

          const categoryTitle = categorySlug
            ? "alquiler " + categorySlug
            : categorieText;

          const categoriesParams = {
            with: ["tags_filters", "keywords"],
          };
          setInCategory(true);
          dispatch({ type: "UPDATE_CATEGORIE", payload: categoryTitle });
          fetch(
            `${API_BASE_URL}/categories/${keyValue}?${encodeURI(
              encoder.encode(categoriesParams)
            )}`
          )
            .then((response) => response.json())
            .then((data) => {
              setSeoText(data);
              setKeywords(data.keywords);
            });
        }
      }
    } else {
      dispatch({ type: "UPDATE_CATEGORIE", payload: "Todos los productos en alquiler" });
    }
  }, [totalProductApi]);

  function handleRemoveCalendar() {
    setDateRange([null, null]);
    dispatch({ type: "REMOVE_FILTER", payload: "available_dates" });
  }

  const category = {
    name: state.categorie || "Categoría no especificada",
    description: seoText?.seo_text || "Explora nuestra selección de productos.",
    slug: state.categorie ? state.categorie.toLowerCase().replace(/ /g, "-") : "",
    seoText: seoText?.seo_text || "",
  };


  return (
    <div>
      <section className="container m-auto py-0 pt-4 md:py-2 px-0 lg:px-32 lg:sticky top-0 z-[2]">
        <div className="flex-col items-center hidden w-full gap-6 px-6 py-6 border md:flex md:bg-white rounded-2xl lg:rounded-full lg:pl-12 lg:flex-row border-light">
          <div className="flex flex-col items-center justify-start flex-1 w-full gap-2 md:gap-6 lg:pl-6 lg:flex-row">
            <div className="flex items-center flex-1 w-full gap-3 py-2 transition-all duration-150 ease-in-out bg-white border-black rounded-full px-44 md:rounded-none md:p-0 md:border-r lg:border-b-0 lg:border-r stroke-black border-opacity-10 hover:stroke-primary lg:w-auto">
              <CartPlp />
              <div ref={productInputRef} className="relative w-full">
                <input
                  className="w-full font-bold text-black border-none appearance-none focus:outline-none placeholder-darkgray placeholder:font-bold focus:placeholder-gray input-search"
                  id="nombre"
                  type="text"
                  value={searchInputValue}
                  placeholder="¿Qué quieres alquilar?"
                  onChange={handleSearchInputChange}
                />
                {rentalOptions.length > 0 && (
                  <div
                    ref={productModalRef}
                    className="absolute z-50 mt-6 bg-white border shadow-md min-w-max top-full rounded-xl border-zinc-100"
                  >
                    <div className="flex flex-col gap-4 p-4 overflow-scroll max-h-48">
                      {rentalOptions.map((record, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 cursor-pointer"
                          onClick={handleItemClick(
                            record,
                            setRentalOptions,
                            setSearchInputValue,
                            true
                          )}
                        >
                          <span className="min-w-[15px] min-h-[15px] border border-primary inline-block rounded-full checkbox-filter"></span>
                          <input
                            type="checkbox"
                            className="hidden"
                            name=""
                            id=""
                          />
                          <label
                            htmlFor=""
                            className="text-sm text-left cursor-pointer"
                          >
                            {record.text}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="relative flex items-center flex-1 w-full gap-3 px-4 py-2 transition-all duration-150 ease-in-out bg-white border-black rounded-full md:rounded-none md:p-0 lg:border-b-0 lg:border-r stroke-black border-opacity-10 hover:stroke-primary lg:w-auto">
              <CalendarPlp />
              <Flatpickr
                options={{
                  mode: "range",
                  dateFormat: "d-m-Y",
                  locale: "es",
                  minDate: "today",
                }}
                placeholder="¿Cuándo?"
                onChange={handleDateChange}
                value={dateRange}
                className="w-full font-bold lg:w-auto"
              />
              {dateRange[0] && (
                <span
                  className="absolute top-[-10px] right-0 py-2 px-8"
                  onClick={() => handleRemoveCalendar()}
                >
                  <RemoveFilterPlp />
                </span>
              )}
            </div>
            <ZipCodeInput
              zipPostalInputValue={zipPostalInputValue}
              handleWhereTopBar={handleWhereTopBar}
              where={where}
              handleItemClick={handleItemClick}
              setZipPostalInputValue={setZipPostalInputValue}
              setWhere={setWhere}
            />
          </div>
        </div>
      </section>
      <div className="sticky flex items-center gap-2 px-4 top-4 z-[99]">
        <button
          onClick={openDrawerTop2}
          className="flex items-center justify-center gap-4 align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none button shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none sticky top-6 lg:hidden z-50 text-white rounded-full bg-primary p-6 w-1/2"
        >
          <SearchPlp2 />
          Buscador
        </button>
        <button
          onClick={openDrawerTop}
          className="flex items-center justify-center gap-4 align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none button shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none sticky top-6 lg:hidden z-50 text-primary rounded-full bg-white w-1/2 p-6"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 9C21.6569 9 23 7.65685 23 6C23 4.34315 21.6569 3 20 3C18.3431 3 17 4.34315 17 6C17 7.65685 18.3431 9 20 9Z"
              stroke="#58A291"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 21C11.6569 21 13 19.6569 13 18C13 16.3431 11.6569 15 10 15C8.34315 15 7 16.3431 7 18C7 19.6569 8.34315 21 10 21Z"
              stroke="#58A291"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 6H1"
              stroke="#58A291"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 18H7"
              stroke="#58A291"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 18H23"
              stroke="#58A291"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filtrar
        </button>
        <Drawer
          placement="top"
          open={openTop}
          size="1000"
          onClose={closeDrawerTop}
          className="p-4 "
          overlayProps={{
            className: "",
          }}
        >
          <div className="w-full top-[-500px] mt-12">
            {/* <Breadcrumb /> */}
            <div className="flex items-center justify-center w-full gap-4 h-fit">
              <button
                onClick={closeDrawerTop}
                className="flex items-center justify-center gap-4 align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none button shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none sticky top-6 lg:hidden z-50 text-primary rounded-full bg-transparent p-4 w-1/2"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.0002 2L1.75879 22"
                    stroke="#58A291"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.0002 22L1.75879 2"
                    stroke="#58A291"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Cerrar
              </button>
            </div>
            <div className="flex items-center gap-2">
              {productCategory ? (
                <p className="mt-4 text-left h3 text-primary lowercase">
                  {productCategory}
                </p>
              ) : (
                <p className="mt-4 text-left h3 text-primary lowercase te">
                  {state.categorie}
                </p>
              )}
            </div>

            <div className="flex flex-wrap items-end justify-between gap-2 mt-9">
              <div className="text-darkgray h4">Filtrar por:</div>
              <a>
                <p
                  className="font-bold transition-all duration-150 ease-in-out cursor-pointer text-secondary body hover:text-red-400"
                  onClick={handleResetFilters}
                >
                  Limpiar filtros
                </p>
              </a>
            </div>
            <div></div>
            <div className="mt-4 overflow-y-auto h-[32rem] accordion">
              <Filters2
                filters={filtersContent}
                fetchedFilters={fetchedFilters}
                filtersContent={filtersContent}
                whereTopBar={openWhereFilters}
                keywords={keywords}
              />
            </div>
          </div>
        </Drawer>
        <Drawer
          placement="top"
          open={openTop2}
          size="1000"
          onClose={closeDrawerTop2}
          className="p-4 sm:hidden"
          overlayProps={{
            className: "",
          }}
        >
          <div className="w-full top-[-500px] mt-6">
            <div className="flex flex-col items-center w-full gap-6 px-0 py-6 md:border md:bg-white rounded-2xl lg:rounded-full lg:pl-12 lg:flex-row border-light">
              <div className="flex flex-col items-center justify-start flex-1 w-full gap-2 md:gap-6 lg:pl-6 lg:flex-row">
                <div className="flex items-center justify-center w-full">
                  <button
                    onClick={closeDrawerTop2}
                    className="flex mb-6 items-center justify-center gap-4 align-middle select-none font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none button shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none sticky top-6 lg:hidden z-50 text-primary rounded-full bg-transparent p-4 w-1/2"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.0002 2L1.75879 22"
                        stroke="#58A291"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M23.0002 22L1.75879 2"
                        stroke="#58A291"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Cerrar
                  </button>
                </div>
                {/* <h2 className="justify-center w-full gap-2 pb-6 text-center text-black lowercase lg:flex h1 current-text">
                  Alquila{" "}
                  <span className="text-primary">todas las herramientas</span>{" "}
                  en un solo lugar
                </h2> */}
                <div className="flex items-center flex-1 w-full gap-3 px-4 py-4 transition-all duration-150 ease-in-out border-black rounded-full bg-light md:rounded-none md:p-0 md:border-r lg:border-b-0 lg:border-r stroke-black border-opacity-10 hover:stroke-primary lg:w-auto">
                  <CartPlp />
                  <div ref={productInputDrawerRef} className="relative w-full">
                    <input
                      className="w-full font-bold text-black bg-transparent border-none appearance-none focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray input-search"
                      id="nombre"
                      type="text"
                      value={searchInputValue}
                      placeholder="¿Qué quieres alquilar?"
                      onChange={handleSearchInputChange}
                    />
                    {rentalOptions.length > 0 && (
                      <div
                        ref={productModalDrawerRef}
                        className="absolute z-50 mt-6 bg-white border shadow-md min-w-max top-full rounded-xl border-zinc-100"
                      >
                        <div className="flex flex-col gap-4 p-4 overflow-scroll max-h-48">
                          {rentalOptions.map((record, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-2 cursor-pointer"
                              onClick={handleItemClick(
                                record,
                                setRentalOptions,
                                setSearchInputValue,
                                true
                              )}
                            >
                              <span className="min-w-[15px] min-h-[15px] border border-primary inline-block rounded-full checkbox-filter"></span>
                              <input
                                type="checkbox"
                                className="hidden"
                                name=""
                                id=""
                              />
                              <label
                                htmlFor=""
                                className="text-sm text-left cursor-pointer"
                              >
                                {record.text}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative flex items-center flex-1 w-full gap-3 px-4 py-4 transition-all duration-150 ease-in-out border-black rounded-full bg-light md:rounded-none md:p-0 lg:border-b-0 lg:border-r stroke-black border-opacity-10 hover:stroke-primary lg:w-auto">
                  <CalendarPlp />
                  <Flatpickr
                    options={{
                      mode: "range",
                      dateFormat: "d-m-Y",
                      locale: "es",
                      minDate: "today",
                    }}
                    placeholder="¿Cuándo?"
                    onChange={handleDateChange}
                    value={dateRange}
                    className="w-full font-bold text-black bg-transparent border-none appearance-none focus:outline-none placeholder-primary placeholder:font-bold focus:placeholder-gray"
                  />
                  {dateRange[0] && (
                    <span
                      className="absolute top-[-10px] right-0 py-2 px-8"
                      onClick={() => handleRemoveCalendar()}
                    >
                      <RemoveFilterPlp />
                    </span>
                  )}
                </div>
                <ZipCodeInput
                  zipPostalInputValue={zipPostalInputValue}
                  handleWhereTopBar={handleWhereTopBar}
                  where={where}
                  handleItemClick={handleItemClick}
                  setZipPostalInputValue={setZipPostalInputValue}
                  setWhere={setWhere}
                />
              </div>
              <a onClick={closeDrawerTop2}>
                <button className="flex items-center justify-center w-[90vw] gap-2 py-6 text-white transition-all duration-150 ease-in-out rounded-full bg-primary button stroke-white px-9 hover:bg-opacity-90">
                  <SearchPlp />
                  <p>Buscar</p>
                </button>
              </a>
            </div>
          </div>
        </Drawer>
      </div>
      <Breadcrumbs category={state.categorie} />

      <section className="flex flex-col w-full bg-light md:flex-row">
        <aside
          className="hidden sm:block sticky top-24 mt-[80px] left-0 w-1/4 h-screen transition-transform -translate-x-full sm:translate-x-0 overflow-y-auto pl-9 pr-8"
          ariaLabel="Sidebar"
        >
          <div className="w-full">
            {/* <Breadcrumb /> */}
            <div className="flex items-center gap-2">
              <h1 className="mt-4 text-left h3 text-primary lowercase">
                {/* alquiler maquinaria  */}
                {state.categorie}
              </h1>
            </div>
            <div className="flex flex-wrap items-end justify-between gap-2 mt-9">
              <div className="text-darkgray h4">filtrar por:</div>
              <a>
                <p
                  className="font-bold transition-all duration-150 ease-in-out cursor-pointer text-secondary body hover:text-red-400"
                  onClick={handleResetFilters}
                >
                  limpiar filtros
                </p>
              </a>
              <div className="flex flex-wrap items-start justify-start w-full gap-4">
                {keysFilters.map((filter, index) => {
                  if (
                    filtersContent &&
                    filtersContent.filters &&
                    filter !== "with_offers" &&
                    filtersContent.filters[filter]
                  ) {
                    return (
                      <span className="relative py-2 pl-10 pr-4 rounded-full cursor-default bg-gray lowercase">
                        {filtersContent.filters[filter][2]}
                        <span
                          className="absolute top-1/2 left-2 translate-y-[-50%] cursor-pointer"
                          onClick={removeFilter(
                            filtersContent.filters[filter][3],
                            filter
                          )}
                        >
                          <img className="max-w-[25px]" src={cross} alt="" />
                        </span>
                      </span>
                    );
                  }
                })}
              </div>
            </div>
            <div></div>
            <div className="mt-4 accordion">
              <Filters2
                filters={filtersContent}
                fetchedFilters={fetchedFilters}
                filtersContent={filtersContent}
                whereTopBar={openWhereFilters}
                keywords={keywords}
              />
            </div>
          </div>
        </aside>
        <div className="w-full md:w-3/4 md:ml-auto md:pr-9 md:pl-2">
          <div className="pt-2 lg:pt-6 pb-2 lg:top-0 relative z-[1] select-none md:flex justify-center lg:justify-end px-4 my-2">
            <div className="pt-0 lg:pt-4 hidden md:block lg:absolute left-0 top-1/2 translate-y-[-50%]">
              Encontrados{" "}
              <span className="font-bold text-primary">
                {totalProductApi.total === 0
                  ? totalProductApi.total
                  : totalProductApi.total}
              </span>{" "}
              productos
            </div>
            <div className="flex relative items-center justify-center border border-light lg:border-none lg:justify-end w-[90vw] lg:w-fit gap-2 px-4 py-2 lg:py-0 font-bold text-black bg-transparent rounded-full body text-start">
              <div
                className="flex items-center cursor-pointer"
                onClick={togglePerPage}
              >
                <p>Productos por página </p>
                <div className="flex items-center justify-center ml-2 text-xs text-center border rounded-lg w-9 h-7 border-primary focus:ring-0 focus:outline-none focus:border-2">
                  {perPage}
                </div>
              </div>
              <div
                className={` ${
                  perPageByOpen ? "block" : "hidden"
                } absolute bg-white w-22 right-10 top-12 space-y-3 px-6 py-9 rounded-xl shadow-md shadow-zinc-50 border border-zinc-100`}
                id="sortBy"
              >
                <div className="flex gap-4">
                  <div
                    className="flex gap-2 mx-auto cursor-pointer"
                    onClick={() => handleChangePerPage(21)}
                  >
                    <label className="font-bold transition-all duration-150 ease-in-out cursor-pointer body text-dark hover:text-primary">
                      21
                    </label>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div
                    className="flex gap-2 mx-auto cursor-pointer"
                    onClick={() => handleChangePerPage(50)}
                  >
                    <label className="font-bold transition-all duration-150 ease-in-out cursor-pointer body text-dark hover:text-primary">
                      50
                    </label>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div
                    className="flex gap-2 mx-auto cursor-pointer"
                    onClick={() => handleChangePerPage(75)}
                  >
                    <label className="font-bold transition-all duration-150 ease-in-out cursor-pointer body text-dark hover:text-primary">
                      100
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center border border-light lg:border-none lg:justify-end w-[90vw] lg:w-fit gap-2 px-4 py-2 lg:py-0 font-bold text-black bg-transparent rounded-full body">
              <div
                className="flex items-center cursor-pointer"
                id="sortByTrigger"
                onClick={toggleSortBy}
              >
                <p>Ordenar por :</p>
                <span className="pl-2 text-primary">{sortByText}</span>
              </div>
              <div className="open-icon stroke-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke=""
                  width="18"
                  height="18"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
            {/* Sort By Options */}
            <div
              className={` ${
                sortByOpen ? "block" : "hidden"
              } absolute bg-white w-[90vw] lg:w-fit lg:right-0 top-24 lg:top-20 space-y-3 px-6 py-9 rounded-xl shadow-md shadow-zinc-50 border border-zinc-100`}
              id="sortBy"
            >
              <div className="flex gap-4">
                <div className="flex items-end gap-2">
                  <label
                    className="font-bold transition-all duration-150 ease-in-out body text-dark hover:text-primary"
                    onClick={() => handleSortPrice("asc", "Precio asc.")}
                  >
                    Precio asc.
                  </label>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex items-end gap-2">
                  <label
                    className="font-bold transition-all duration-150 ease-in-out body text-dark hover:text-primary"
                    onClick={() => handleSortPrice("desc", "Precio des.")}
                  >
                    Precio des.
                  </label>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="flex items-end gap-2">
                  <label
                    className="font-bold transition-all duration-150 ease-in-out body text-dark hover:text-primary"
                    onClick={() => handleSortPrice("", "Por defecto")}
                  >
                    Por defecto
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className="flex flex-wrap gap-4 px-4 md:px-0">
                {loading ? (
                  <>
                    <ProductCardSkeleton />
                    <ProductCardSkeleton />
                    <ProductCardSkeleton />
                    <ProductCardSkeleton />
                    <ProductCardSkeleton />
                    <ProductCardSkeleton />
                  </>
                ) : products.length > 0 ? (
                  products.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      isAuthenticated={isAuthenticated}
                      useH2={true}
                      breadcrumbCategory={state.categorie}
                    />
                  ))
                ) : (
                  <div className="flex items-center justify-center w-full mt-12 mb-12">
                    <div className="w-full p-8 bg-white border rounded-xl border-primary">
                      <p className="text-lg">
                        No hemos encontrado lo que buscabas, ¡puedes ponerte en
                        contacto con nuestro personal especializado!
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Pagination */}
            {products.length > 0 ? (
              <div className="w-full py-24">
                <p className="flex items-center justify-center gap-2 pt-12 border-t border-gray">
                  Mostrando{" "}
                  <span className="font-bold text-primary">
                    {totalProductApi.to - totalProductApi.from + 1}
                  </span>{" "}
                  de{" "}
                  <span className="font-bold text-primary">
                    {totalProductApi.total}
                  </span>{" "}
                  productos
                </p>
                <div className="flex items-center justify-center gap-1 mt-4">
                  <button
                    className={`bg-transparent rounded-full h-9 w-9 flex justify-center items-center text-darkgray body font-bold hover:bg-white hover:text-primary transition-all ease-in-out duration-150 ${
                      state.urlTestParams.page === 1 ? "cursor-not-allowed" : ""
                    }`}
                    onClick={() => changePage(state.urlTestParams.page - 1)}
                    disabled={state.urlTestParams.page === 1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke=""
                      width="24"
                      height="24"
                    >
                      <path
                        fill="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      ></path>
                    </svg>
                  </button>
                  {[...Array(lastPage)].map((_, i) => {
                    // Mostrar la página actual, dos páginas por encima, dos por debajo y la última página
                    if (
                      i === 0 || // Primera página
                      i === state.urlTestParams.page - 1 || // Página actual
                      i === lastPage - 1 || // Última página
                      (i >= state.urlTestParams.page - 2 &&
                        i <= state.urlTestParams.page + 1) || // Dos páginas antes y después de la actual
                      (i === state.urlTestParams.page - 3 &&
                        state.urlTestParams.page > 3) || // Puntos suspensivos antes de la página actual si currentPage > 4
                      (i === state.urlTestParams.page + 1 &&
                        state.urlTestParams.page < lastPage - 2) // Puntos suspensivos después de la página actual si currentPage < lastPage - 3
                    ) {
                      return (
                        <button
                          key={i}
                          className={`bg-transparent rounded-full h-9 w-9 flex justify-center items-center text-${
                            i === state.urlTestParams.page - 1
                              ? "primary"
                              : "darkgray"
                          } body font-bold hover:bg-white hover:text-primary transition-all ease-in-out duration-150`}
                          onClick={() => changePage(i + 1)}
                        >
                          {i + 1}
                        </button>
                      );
                    }

                    // Mostrar puntos suspensivos si no estamos en una de las situaciones anteriores
                    else if (
                      (i === state.urlTestParams.page - 5 &&
                        state.urlTestParams.page > 4) || // Puntos suspensivos antes de la primera página
                      (i === state.urlTestParams.page + 3 &&
                        state.urlTestParams.page < lastPage - 3) // Puntos suspensivos después de la última página
                    ) {
                      return <span key={`ellipsis-${i}`}>...</span>;
                    }
                    return null; // Ocultar el resto de las páginas
                  })}
                  <button
                    className={`bg-transparent rounded-full h-9 w-9 flex justify-center items-center text-darkgray body font-bold hover:bg-white hover:text-primary transition-all ease-in-out duration-150 ${
                      state.urlTestParams.page === lastPage
                        ? "cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => changePage(state.urlTestParams.page + 1)}
                    disabled={state.urlTestParams.page === lastPage}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke=""
                      width="24"
                      height="24"
                    >
                      <path
                        fill="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <div className="flex flex-col sm:flex-row gap-8">
        <div className="sm:w-1/4 pl-9">
          {/* <div className="flex flex-row flex-wrap gap-2">
            {inCategory &&
              seoText &&
              seoText.tags_filters.map((keyword, index) => (
                <span className="inline-block px-4 py-2 text-white rounded-full bg-primary">
                  #{keyword.description}&nbsp;
                </span>
              ))}
          </div> */}
        </div>
        <div className="sm:w-3/4 px-8">
          <div>
            <p className="text-left">{inCategory && seoText.seo_text}</p>
          </div>
        </div>
      </div>
      <CategorySchema category={category} />
    </div>
  );
}

export default Plp2;
